import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createBlock(_component_v_select, {
    modelValue: _ctx.song,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.song) = $event)),
    items: _ctx.$store.state.filterSongs,
    "item-title": "name",
    "item-value": "name",
    variant: "solo",
    "menu-props": {
                      maxHeight: 300
                    },
    label: "Song"
  }, null, 8, ["modelValue", "items"]))
}