
import {Options, Vue} from "vue-class-component";
import CategoryFilter from "@/components/CategoryFilter.vue";
import BandFilter from "@/components/BandFilter.vue";
import SongFilter from "@/components/SongFilter.vue";
import SongsCounter from "@/components/SongsCounter.vue";

@Options({
  components: {SongsCounter, SongFilter, BandFilter, CategoryFilter}
})
export default class Filter extends Vue {
}
