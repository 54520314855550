
import {Options, Vue} from "vue-class-component";
import FirebaseService from "@/shared/services/firebase.service";
import SongsCounter from "@/components/SongsCounter.vue";
import SongView from "@/components/SongView.vue";
import NoSongs from "@/components/NoSongs.vue";

@Options({
  components: {NoSongs, SongView, SongsCounter},
  inject: ['useFilter']
})
export default class Home extends Vue {
  private firebaseService = new FirebaseService();

  private usedIdx: number[] = [];

  useFilter!: () => void;

  mounted() {
    this.useFilter();

    document.title = `Random songs | ${process.env.VUE_APP_TITLE}`;
  }

  getRandomSong() {
    const songs = this.$store.state.filteredMySongs;

    if (this.usedIdx.length >= songs.length) {
      this.usedIdx = [];
    }

    let random: number;
    do {
      random = this.getRandomInt(songs.length);
    } while (this.usedIdx.find(el => el === random));

    this.usedIdx.push(random);

    const name = this.$store.state.mySongs[this.usedIdx[this.usedIdx.length - 1]].song;
    document.title = `${name} | ${process.env.VUE_APP_TITLE}`;
  }

  getRandomInt(max: number): number {
    return Math.floor(Math.random() * max);
  }
}
