/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL.replace('/mysongs', '')}service-worker.js`, {
    ready (registration) {
      console.log(
          'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered (registration) {
      console.log('Service worker has been registered.')
    },
    cached (registration) {
      console.log('Content has been cached for offline use.')
    },
    updatefound (registration) {
      if (registration) { // if there is a SW active
        console.log('Service Worker update detected!', registration);
      }
    },
    updated (registration) {
      if (/*confirmationResult && */registration?.waiting) {
        registration.waiting.postMessage({action: "skipWaiting", type: `SKIP_WAITING`});
      }
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}

let refreshing = false;
navigator.serviceWorker.addEventListener("controllerchange", e=>{
  if (refreshing) {
    return;
  }
  window.location.reload();
  refreshing = true
});
