import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "d-flex align-items-center justify-content-center",
  style: {"height":"400px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_btn, {
      color: "primary",
      onClick: _ctx.addSong
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Add first song ")
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}