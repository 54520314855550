
import { Options, Vue } from 'vue-class-component';
import FirebaseService from "@/shared/services/firebase.service";
import Filter from "@/components/Filter.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import {isAdmin} from "@/shared/const";


@Options({
  components: {ConfirmDialog, Filter},
  data() {
    return {
      drawer: false,
      snackbarText: '',
      snackbar: false,
      showFilter: false,
      showFilterButton: false,
      navigator: window.navigator
    }
  },
  provide() {
    return {
      useFilter: () => this.toggleFilterButton(true),
      confirmDialog: (confirmText: string) => this.$refs.confirmDialog.open(confirmText)
    }
  },
  watch: {
    $route (){
      this.showFilterButton = false;
      this.changeTitle();
    },
    '$store.state.message': {
      handler(newValue) {
        if (newValue) {
          this.snackbarText = newValue;
          this.snackbar = true;
          this.$store.commit('resetMessage');
        }
      }
    }
  }
})
export default class App extends Vue {
  private firebaseService = new FirebaseService();

  showFilterButton!: boolean;

  showFilter!: boolean;

  isAdmin = isAdmin;

  appVersion = process.env.VUE_APP_VERSION;


  mounted() {
    this.firebaseService.checkLogin();
  }

  login() {
    this.firebaseService.login();
  }

  logout() {
    this.firebaseService.logout();
  }

  toggleFilterButton(use = false) {
    this.showFilterButton = use;
  }

  changeTitle() {
    document.title = process.env.VUE_APP_TITLE;
  }

  goHome() {
    this.$router.push('/');
  }
}
