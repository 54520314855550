import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d07bab86"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position-relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_select, {
      modelValue: _ctx.band,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.band) = $event)),
      items: _ctx.$store.state.bands,
      "item-title": "name",
      "item-value": "name",
      variant: "solo",
      "hide-details": true,
      "menu-props": {
                    maxHeight: 300
                  },
      "return-object": true,
      label: "Band"
    }, null, 8, ["modelValue", "items"]),
    (_ctx.band)
      ? (_openBlock(), _createBlock(_component_v_btn, {
          key: 0,
          class: "button-clear",
          icon: "mdi-close-circle-outline",
          size: "small",
          variant: "flat",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setBand()))
        }))
      : _createCommentVNode("", true)
  ]))
}