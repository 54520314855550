
import {Options, Vue} from "vue-class-component";
import SongCategories from "@/components/SongCategories.vue";

@Options({
  components: {SongCategories},
  props: {
    index: -1
  },
  data() {
    return {
      navigator: window.navigator
    };
  }
})
export default class SongView extends Vue {

  index!: number;
  replaceInText(text: string) {
    return text.replaceAll('\\n', '\n')
  }

  goToUrl(url: string) {
    window.open(url, '_blank');
  }

  edit() {
    this.$router.push({name: 'editSong', params: {songId: this.$store.state.mySongs[this.index].uid}});
  }
}
