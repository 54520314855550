
import {Options, Vue} from "vue-class-component";
import FirebaseService from "@/shared/services/firebase.service";
import SongsCounter from "@/components/SongsCounter.vue";
import {MySongModel} from "@/shared/models/mySong.model";
import NoSongs from "@/components/NoSongs.vue";
import SongCategories from "@/components/SongCategories.vue";
import SongSearchFIlter from "@/components/SongSearchFilter.vue";

@Options({
  components: {SongSearchFilter: SongSearchFIlter, SongCategories, NoSongs, SongsCounter},
  inject: ['useFilter'],
  data() {
    return {
      navigator: window.navigator
    };
  }
})
export default class MySongs extends Vue {
  private firebaseService = new FirebaseService();

  useFilter!: () => void;

  mounted() {
    this.useFilter();
  }

  beforeUnmount() {
    this.$store.commit('setFilterSearch', '');
  }

  edit(song: MySongModel) {
    this.$router.push({name: 'editSong', params: {songId: song.uid}});
  }

  view(song: MySongModel) {
    this.$router.push({name: 'viewSong', params: {songId: song.uid}});
  }

  replaceInText(text: string) {
    return text.replaceAll('\\n', '\n')
  }
}
