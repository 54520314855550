
import {Options, Vue} from "vue-class-component";
import FirebaseService from "@/shared/services/firebase.service";

@Options({
  data() {
    return {
      song: ''
    }
  },
  watch: {
    song: {
      handler(newValue) {
        this.$store.commit('setFilterSong', newValue);
      }
    },
    '$store.state.filter.song': {
      handler(newValue) {
        this.song = newValue;
      }
    },
    '$store.state.filter.band': {
      handler(newValue) {
        this.song = '';

        if (!newValue) {
          this.$store.commit('populateFilterSongs', []);
          return
        }

        this.firebaseService.getSongs(newValue.uid, 'populateFilterSongs');
      }
    }
  }
})
export default class SongFilter extends Vue {
  private firebaseService = new FirebaseService();
}
