import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, { class: "category-filter m-3" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.state.categories, (c) => {
        return (_openBlock(), _createBlock(_component_v_switch, {
          key: c.uid,
          modelValue: _ctx.categories,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.categories) = $event)),
          label: c.name,
          value: c.uid,
          color: "success",
          "hide-details": "",
          style: _normalizeStyle('--category-color:' + c.color),
          density: "compact"
        }, null, 8, ["modelValue", "label", "value", "style"]))
      }), 128))
    ]),
    _: 1
  }))
}