
import {Options, Vue} from "vue-class-component";
import {CategoryModel} from "@/shared/models/category.model";
import FirebaseService from "@/shared/services/firebase.service";

@Options({
  data() {
    return {
      control: {},
      color: {},
      validity: {},
      menu: {},
      validityNew: undefined,
      newCategoryName: 'New category',
      newCategoryColor: '',
      menuNewColor: false,
      showNew: false,
      categories: []
    }
  },
  watch: {
    '$store.state.categories': {
      handler(categories) {
        this.categories = [...categories];
        if (categories.length > 0) {
          categories.forEach((el: CategoryModel) => {
            this.setControl(el);
          });
        }
      },
      deep: true,
      immediate: true
    }
  }
})
export default class CategoryList extends Vue {

  firebaseService = new FirebaseService();

  categories!: CategoryModel[];

  control!: { [key: string]: string };

  validity!: { [key: string]: undefined | string };

  color!: { [key: string]: undefined | string };

  validityNew!: undefined | string;

  newCategoryName!: string;

  newCategoryColor!: string;

  showNew!: boolean;

  mounted() {
    document.title = `My Categories | ${process.env.VUE_APP_TITLE}`;
  }

  beforeUnmount() {
    this.$store.commit('populateCategories', this.categories);
  }

  updateCategoryName(category: CategoryModel) {
    const uid = category.uid as string;
    const name = this.control[uid];
    const color = this.color[uid];
    const newCategory = {...category, name, color};
    this.firebaseService.updateCategory(newCategory, uid)
        .then(() => {
          const index = this.categories.findIndex(el => el.uid === uid);
          this.categories[index] = {...newCategory};
        })
        .catch(err => {
          console.error(err);
        });
  }

  removeNew() {
    this.showNew = false;
    this.validityNew = undefined;
    this.newCategoryName = 'New category';
    this.newCategoryColor = '';
  }

  setControl(category: CategoryModel) {
    const uid = category.uid as string;
    this.control[uid] = category.name;
    this.validity[uid] = undefined;
    this.color[uid] = category.color;
  }

  resetCategory(category: CategoryModel) {
    this.setControl(category);
  }

  createCategory() {
    const category: CategoryModel = {
      name: this.newCategoryName,
      color: this.newCategoryColor,
      userId: this.$store.state.user?.uid as string
    };
    this.firebaseService.createCategory(category)
        .then((res: any) => {
          this.removeNew();
          const newCategory = {...category, uid: res.id};
          this.setControl(newCategory);
          this.categories.push({...category, uid: res.id});
        })
        .catch((err) => {
          console.error(err);
        });
  }
}
