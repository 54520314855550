
import {Options, Vue} from "vue-class-component";
import {BandModel, SongModel} from "@/shared/models/song.model";
import {isAdmin} from "@/shared/const";
import {UserModel} from "@/shared/user.model";
import FirebaseService from "@/shared/services/firebase.service";

@Options({
  data() {
    return {
      control: {},
      validity: {},
      validityNew: undefined,
      newSongName: 'New song',
      showNew: false,
      songs: [],
      band: '',
    }
  },
  watch: {
    band: {
      handler(newValue) {
        this.song = '';
        this.showAddSong = false;
        if (newValue.uid) {
          this.getSongs(newValue.uid);
        } else {
          this.songs = [];
        }
      }
    },
    '$store.state.songs': {
      handler(songs) {
        this.songs = [...songs];
        if (songs.length > 0) {
          songs.forEach((el: SongModel) => {
            this.setControl(el);
          });
        }
      },
      deep: true,
      immediate: true
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (isAdmin((vm.$store.state.user as UserModel).uid || '')) {
        return true;
      } else {
        vm.$router.push('home');
      }
    })
  }
})
export default class SongList extends Vue {

  firebaseService = new FirebaseService();

  band!: BandModel | string;

  songs!: SongModel[];

  control!: { [key: string]: string };

  validity!: { [key: string]: undefined | string };

  validityNew!: undefined | string;

  newSongName!: string;

  showNew!: boolean;

  mounted() {
    document.title = `Songs | ${process.env.VUE_APP_TITLE}`;
  }

  beforeUnmount() {
    this.$store.commit('populateSongs', this.songs);
  }

  getSongs(bandId: string) {
    this.firebaseService.getSongs(bandId);
  }

  updateSongName(song: SongModel) {
    const uid = song.uid as string;
    const name = this.control[uid];
    const newSong = {...song, name};
    this.firebaseService.updateSong(newSong, uid, song.name)
        .then(() => {
          const index = this.songs.findIndex(el => el.uid === uid);
          this.songs[index] = {...newSong};
        })
        .catch(err => {
          console.error(err);
        });
  }

  removeNew() {
    this.showNew = false;
    this.validityNew = undefined;
    this.newSongName = 'New song';
  }

  setControl(song: SongModel) {
    const uid = song.uid as string;
    this.control[uid] = song.name;
    this.validity[uid] = undefined;
  }

  resetSong(song: SongModel) {
    this.setControl(song);
  }

  createSong() {
    const song: SongModel = {
      name: this.newSongName,
      band: (this.band as BandModel).uid as string
    };
    this.firebaseService.createSong(song)
        .then((res: any) => {
          this.removeNew();
          const newSong = {...song, uid: res.id};
          this.setControl(newSong);
          this.songs.push({...song, uid: res.id});
        })
        .catch((err) => {
          console.error(err);
        });
  }

  filter = (itemText: string, queryText: string) => {
    return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
  }
}
