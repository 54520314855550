
import {Options, Vue} from "vue-class-component";

@Options({
  data() {
    return {
      band: ''
    }
  },
  watch: {
    band: {
      handler(newValue) {
        this.$store.commit('setFilterBand', newValue);
      }
    },
    '$store.state.filter.band': {
      handler(newValue) {
        this.setBand(newValue);
      }
    }
  }
})
export default class BandFilter extends Vue {
  band!: any;

  setBand(value = '') {
    this.band = value;
  }
}
