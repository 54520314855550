
import {Options, Vue} from "vue-class-component";
import FirebaseService from "@/shared/services/firebase.service";
import {BandModel, SongModel} from "@/shared/models/song.model";
import {CategoryModel} from "@/shared/models/category.model";
import {MySongModel} from "@/shared/models/mySong.model";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

@Options({
  data() {
    return {
      showAddBand: false,
      showAddSong: false,
      showAddCategory: false,
      showDialog: false,
      isFormValid: false,
      band: '',
      song: '',
      lyrics: '',
      lyricsUrl: '',
      chords: '',
      tabUrl: '',
      helpers: '',
      categories: []
    }
  },
  watch: {
    band: {
      handler(newValue) {
        this.song = '';
        this.showAddSong = false;
        if (newValue.uid) {
          this.getSongs(newValue.uid);
        } else {
          this.showAddBand = (this.$refs.bands as any).filteredItems.length === 0 && newValue;
        }
      }
    },
    song: {
      handler(newValue) {
        if (!newValue.uid) {
          this.showAddSong = (this.$refs.songs as any).filteredItems.length === 0 && newValue;
        }
      }
    },
    '$store.state.songs': {
      handler(newValue) {
        if (newValue.length > 0 && this.predefinedSongName) {
          this.song = this.$store.getters.songByName(this.predefinedSongName) as SongModel;
          this.predefinedSongName = '';
        }
      }
    }
  },
  inject: ['confirmDialog'],
  beforeRouteLeave(to, from, next) {
    this.confirmDialog(`Did you save everything?\nWe don't want you to lose your song!`)
        .then((result: boolean) => {
          next(result);
        });
  }
})
export default class AddSong extends Vue {

  confirmDialog!: typeof ConfirmDialog.prototype.open;

  categorySearch: string = '';

  createCategoryTriggered = false;

  band!: BandModel | string;

  song!: SongModel | string;

  lyrics!: string;

  lyricsUrl!: string;

  chords!: string;

  tabUrl!: string;

  helpers!: string;

  predefinedSongName = '';

  showAddBand!: boolean;

  showAddSong!: boolean;

  showDialog!: boolean;

  showAddCategory!: boolean;

  uid: string = '';

  // @ts-ignore
  urlRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
  
  categories!: CategoryModel[];

  private firebaseService = new FirebaseService();

  filter = (itemText: string, queryText: string) => {
    return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
  }

  mounted() {
    document.title = `Add Song | ${process.env.VUE_APP_TITLE}`;

    if (this.$route.params.songId) {
      this.uid = this.$route.params.songId as string;
      const song = this.$store.getters.getMySongByUid(this.uid);
      if (!song) {
        this.$router.push({name: 'mySongs'})
      } else {
        this.band = this.$store.getters.bandByName(song.band) as BandModel;
        this.predefinedSongName = song.song;

        this.getSongs(this.band.uid as string);

        this.lyrics = song.lyrics;
        this.lyricsUrl = song.lyricsUrl;
        this.chords = song.chords;
        this.tabUrl = song.tabUrl;
        this.helpers = song.helpers;
        this.categories = this.$store.getters.getCategories(song.categories);

        document.title = `Edit ${song.song} | ${process.env.VUE_APP_TITLE}`;
      }
    }
  }

  getSongs(bandId: string) {
    this.firebaseService.getSongs(bandId);
  }

  addNewBand() {
    const band: BandModel = {name: this.band as string};
    this.firebaseService.createBand(band).then((res: any) => {
      this.firebaseService.getBands();
      this.showAddBand = false;
      this.band = {
        uid: res.id,
        ...band
      };
    });
  }

  addNewSong() {
    const song: SongModel = {name: this.song as string, band: (this.band as BandModel).uid as string};
    this.firebaseService.createSong(song).then((res: any) => {
      this.firebaseService.getSongs((this.band as BandModel).uid as string);
      this.showAddSong = false;
      this.song = {
        uid: res.id,
        ...song
      };
    });
  }

  addNewCategory() {
    this.createCategoryTriggered = true;
    const category: CategoryModel = {name: this.categorySearch, userId: this.$store.state.user?.uid as string};
    this.firebaseService.createCategory(category).then(() => {
      this.firebaseService.getCategories();
    })
    .catch(() => {
      this.createCategoryTriggered = false;
    });
  }

  updateSearch(search: string) {
    if (!this.createCategoryTriggered) {
      setTimeout(() => {
        if (!this.createCategoryTriggered) {
          this.categorySearch = search;
          this.showAddCategory = (this.$refs.category as any).filteredItems.length === 0 && search.length > 0;
        }
      }, 200);
    }
  }

  updateModel(e: any) {
    const value = e.filter((el: any) => typeof el !== 'string');
    const storeData = this.$store.state.categories;
    const newCategories = value.map((el: CategoryModel) => {
      return storeData.find(c => c.uid === el.uid);
    });
    this.categories = newCategories;
  }

  addMySong() {
    if (!(this.band as BandModel).uid && !(this.song as SongModel).uid) {
      return;
    }

    const mySong: MySongModel = this.generateData();

    this.firebaseService.createMySong(mySong)
        .then(() => {
          this.firebaseService.getMySongs();
          this.showDialog = true;
        })
        .catch(() => {

        });

  }

  editMySong() {
    if (!(this.band as BandModel).uid && !(this.song as SongModel).uid) {
      return;
    }

    const mySong: MySongModel = this.generateData();

    this.firebaseService.updateMySong(mySong, this.uid)
        .then(() => {
          this.firebaseService.getMySongs();
        });
  }

  generateData() {
    return {
      song: (this.song as SongModel).name,
      band: (this.band as BandModel).name,
      categories: this.categories ? this.categories.map((el: CategoryModel) => {return el.uid as string}) : [],
      chords: this.chords.replaceAll(/</g, "&lt;").replaceAll(/>/g, "&gt;"),
      helpers: this.helpers.replaceAll(/</g, "&lt;").replaceAll(/>/g, "&gt;"),
      lyrics: this.lyrics.replaceAll(/</g, "&lt;").replaceAll(/>/g, "&gt;"),
      lyricsUrl: this.lyricsUrl,
      tabUrl: this.tabUrl,
      userId: this.$store.state.user?.uid || ''
    }
  }

  goHome() {
    this.$router.push('/');
  }

  addNext() {
    // @ts-ignore
    this.$router.go();
  }
}
