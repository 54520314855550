import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a949da8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-4 category-list" }
const _hoisted_2 = { class: "d-flex align-items-center justify-content-center" }
const _hoisted_3 = { class: "d-flex align-items-center justify-content-center" }
const _hoisted_4 = { class: "d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_color_picker = _resolveComponent("v-color-picker")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
      return (_openBlock(), _createBlock(_component_v_card, {
        key: category.uid,
        class: "mb-3"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_form, {
                modelValue: _ctx.validity[category.uid],
                "onUpdate:modelValue": ($event: any) => ((_ctx.validity[category.uid]) = $event),
                class: "d-flex"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_v_menu, {
                      modelValue: _ctx.menu[category.uid],
                      "onUpdate:modelValue": ($event: any) => ((_ctx.menu[category.uid]) = $event),
                      "close-on-content-click": false,
                      location: "end"
                    }, {
                      activator: _withCtx(({ props }) => [
                        _createElementVNode("div", _mergeProps({ class: "color-selector mr-3 cursor-pointer" }, props, {
                          style: '--category-color: ' + _ctx.color[category.uid] || 'transparent'
                        }), null, 16)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_v_card, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_color_picker, {
                              class: "ma-2",
                              "show-swatches": "",
                              "hide-canvas": "",
                              "hide-inputs": "",
                              "hide-sliders": "",
                              "onUpdate:modelValue": ($event: any) => (_ctx.color[category.uid] = $event),
                              "swatches-max-height": "300px"
                            }, null, 8, ["onUpdate:modelValue"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["modelValue", "onUpdate:modelValue"])
                  ]),
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.control[category.uid],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.control[category.uid]) = $event),
                    placeholder: "Placeholder",
                    variant: "plain",
                    "hide-details": true,
                    class: "'pt-0'",
                    rules: [
                v => !v || `Can't be empty`
              ]
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "rules"]),
                  (_ctx.control[category.uid] !== category.name || _ctx.color[category.uid] !== category.color)
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 0,
                        onClick: ($event: any) => (_ctx.resetCategory(category)),
                        class: "ml-auto",
                        size: "small"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Reset ")
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    : _createCommentVNode("", true),
                  (_ctx.control[category.uid] !== category.name || _ctx.color[category.uid] !== category.color)
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 1,
                        onClick: ($event: any) => (_ctx.updateCategoryName(category)),
                        class: "ml-2",
                        color: "primary",
                        disabled: _ctx.validity[category.uid],
                        size: "small"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Save ")
                        ]),
                        _: 2
                      }, 1032, ["onClick", "disabled"]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1024))
    }), 128)),
    (_ctx.showNew)
      ? (_openBlock(), _createBlock(_component_v_card, {
          key: 0,
          class: "mb-3"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_form, {
                  modelValue: _ctx.validityNew,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.validityNew) = $event)),
                  class: "d-flex"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_v_menu, {
                        modelValue: _ctx.menuNewColor,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.menuNewColor) = $event)),
                        "close-on-content-click": false,
                        location: "end"
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createElementVNode("div", _mergeProps({ class: "color-selector mr-3 cursor-pointer" }, props, {
                            style: '--category-color: ' + _ctx.newCategoryColor || 'transparent'
                          }), null, 16)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_card, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_color_picker, {
                                class: "ma-2",
                                "show-swatches": "",
                                "hide-canvas": "",
                                "hide-inputs": "",
                                "hide-sliders": "",
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.newCategoryColor = $event)),
                                "swatches-max-height": "300px"
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _createVNode(_component_v_text_field, {
                      modelValue: _ctx.newCategoryName,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newCategoryName) = $event)),
                      placeholder: "Placeholder",
                      variant: "plain",
                      autofocus: "",
                      "hide-details": true,
                      class: "'pt-0'",
                      rules: [
                v => !v || `Can't be empty`
              ]
                    }, null, 8, ["modelValue", "rules"]),
                    _createVNode(_component_v_btn, {
                      onClick: _ctx.removeNew,
                      class: "ml-auto",
                      size: "small"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Cancel ")
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_v_btn, {
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.createCategory())),
                      class: "ml-2",
                      color: "primary",
                      disabled: _ctx.validityNew,
                      size: "small"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Save ")
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (!_ctx.showNew)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 0,
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showNew = true)),
            class: "ml-2",
            color: "primary"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Add new ")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}