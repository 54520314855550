
import {Options, Vue} from "vue-class-component";

@Options({
  data() {
    return {
      search: ''
    }
  },
  watch: {
    search: {
      handler(newValue) {
        this.$store.commit('setFilterSearch', newValue);
      }
    },
    '$store.state.filter.search': {
      handler(newValue) {
        this.setSearchValue(newValue);
      },
      immediate: true
    }
  }
})
export default class BandFilter extends Vue {
  search!: any;

  setSearchValue(value = '') {
    this.search = value;
  }
}
