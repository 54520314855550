import { createApp } from 'vue'
import App from './App.vue'

import "bootstrap/dist/css/bootstrap.min.css";

// Vuetify
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import {createVuetify, ThemeDefinition} from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import './registerServiceWorker';

// Store

import { store, key } from './store/store.module';
import {router} from "@/router/router.module";

const myCustomLightTheme: ThemeDefinition = {
    dark: false,
    colors: {
        background: '#669bbc',
        surface: '#f8fbfc',
        primary: '#0077b6',
        'primary-darken-1': '#3700B3',
        secondary: '#03DAC6',
        'secondary-darken-1': '#018786',
        error: '#B00020',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
    }
}

const vuetify = createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: 'myCustomLightTheme',
        themes: {
            myCustomLightTheme,
        }
    },
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        }
    },
})

createApp(App)
    .use(vuetify)
    .use(store, key)
    .use(router)
    .mount('#app')
