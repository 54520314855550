
import {Options, Vue} from "vue-class-component";
import {debounce} from 'lodash';

@Options({
  data () {
    return {
      categories: [],
    }
  },
  watch: {
    categories: {
      handler(newValue) {
        this.setFilter(newValue);
      }
    },
    '$store.state.filter.categories': {
      handler(newValue) {
        this.categories = newValue;
      }
    }
  }
})
export default class CategoryFilter extends Vue {

  setFilter = debounce(this.setFilterCategories, 500);

  setFilterCategories(newValue: string[])  {
    this.$store.commit('setFilterCategories', newValue);
  }
}
