import {createRouter, createWebHistory} from 'vue-router'
import Home from "@/components/Home.vue";
import AddSong from "@/components/AddSong.vue";
import MySongs from "@/components/MySongs.vue";
import ViewSong from "@/components/ViewSong.vue";
import CategoryList from "@/components/CategoryList.vue";
import BandsList from "@/components/BandsList.vue";
import SongList from "@/components/SongList.vue";
import Versions from "@/components/Versions.vue";

const routes = [
    {
        path: '/random',
        name: 'home',
        component: Home
    },
    {
        path: '/add-song',
        name: 'addSong',
        component: AddSong
    },
    {
        path: '/edit-song/:songId',
        name: 'editSong',
        component: AddSong
    },
    {
        path: '/view-song/:songId',
        name: 'viewSong',
        component: ViewSong
    },
    {
        path: '/',
        name: 'mySongs',
        component: MySongs
    },
    {
        path: '/categories',
        name: 'categories',
        component: CategoryList
    },
    {
        path: '/bands',
        name: 'bands',
        component: BandsList
    },
    {
        path: '/songs',
        name: 'songs',
        component: SongList
    },
    {
        path: '/versions',
        name: 'versions',
        component: Versions
    }

]

export const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes
})
