import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_app_bar_title = _resolveComponent("v-app-bar-title")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_Filter = _resolveComponent("Filter")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_app = _resolveComponent("v-app")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_app, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_app_bar, {
          elevation: 2,
          density: "compact"
        }, {
          prepend: _withCtx(() => [
            _createVNode(_component_v_app_bar_nav_icon, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.drawer = !_ctx.drawer))
            })
          ]),
          append: _withCtx(() => [
            (_ctx.showFilterButton && _ctx.$store.getters.isAuth)
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 0,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showFilter = !_ctx.showFilter))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Filter ")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.showFilterButton && _ctx.$store.getters.isAuth)
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 1,
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$store.commit('resetFilter'))),
                  class: _normalizeClass({'invisible': !_ctx.$store.getters.hasFilter}),
                  icon: "mdi-close-circle-outline",
                  size: "small"
                }, null, 8, ["class"]))
              : _createCommentVNode("", true),
            (!_ctx.$store.getters.isAuth)
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 2,
                  onClick: _ctx.login
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Login ")
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_app_bar_title, {
              class: "app-title cursor-pointer",
              onClick: _ctx.goHome
            }, {
              default: _withCtx(() => [
                _createTextVNode(" My Songs "),
                (!_ctx.navigator.onLine)
                  ? (_openBlock(), _createBlock(_component_v_icon, {
                      key: 0,
                      class: "ml-1 offline-icon",
                      size: "x-small",
                      icon: "mdi-cloud-off-outline"
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_navigation_drawer, {
          modelValue: _ctx.drawer,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.drawer) = $event)),
          temporary: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              (_ctx.$store.getters.isAuth)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 0,
                    "prepend-avatar": _ctx.$store.state.user.photoURL,
                    title: _ctx.$store.state.user.displayName
                  }, null, 8, ["prepend-avatar", "title"]))
                : _createCommentVNode("", true),
              _createVNode(_component_v_divider),
              _createVNode(_component_v_list, {
                density: "compact",
                nav: ""
              }, {
                default: _withCtx(() => [
                  (_ctx.$store.getters.isAuth)
                    ? (_openBlock(), _createBlock(_component_v_container, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item, {
                            "prepend-icon": "mdi-archive-music",
                            title: "My Songs",
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push({name: 'mySongs'})))
                          }),
                          _createVNode(_component_v_list_item, {
                            "prepend-icon": "mdi-music",
                            title: "Random",
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$router.push({name: 'home'})))
                          }),
                          (_ctx.navigator.onLine)
                            ? (_openBlock(), _createBlock(_component_v_list_item, {
                                key: 0,
                                "prepend-icon": "mdi-plus",
                                title: "Add song",
                                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$router.push({name: 'addSong'})))
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.navigator.onLine)
                            ? (_openBlock(), _createBlock(_component_v_divider, { key: 1 }))
                            : _createCommentVNode("", true),
                          (_ctx.navigator.onLine)
                            ? (_openBlock(), _createBlock(_component_v_list_item, {
                                key: 2,
                                "prepend-icon": "mdi-tag-multiple",
                                title: "Categories",
                                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$router.push({name: 'categories'})))
                              }))
                            : _createCommentVNode("", true),
                          _createVNode(_component_v_divider),
                          (_ctx.navigator.onLine && _ctx.isAdmin(_ctx.$store.state.user.uid || ''))
                            ? (_openBlock(), _createBlock(_component_v_list_item, {
                                key: 3,
                                "prepend-icon": "mdi-account-group",
                                title: "Bands",
                                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$router.push({name: 'bands'})))
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.navigator.onLine && _ctx.isAdmin(_ctx.$store.state.user.uid || ''))
                            ? (_openBlock(), _createBlock(_component_v_list_item, {
                                key: 4,
                                "prepend-icon": "mdi-music-box-multiple",
                                title: "Songs",
                                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$router.push({name: 'songs'})))
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.isAdmin(_ctx.$store.state.user.uid || ''))
                            ? (_openBlock(), _createBlock(_component_v_divider, { key: 5 }))
                            : _createCommentVNode("", true),
                          (_ctx.navigator.onLine)
                            ? (_openBlock(), _createBlock(_component_v_list_item, {
                                key: 6,
                                "prepend-icon": "mdi-logout",
                                title: "Logout",
                                onClick: _ctx.logout
                              }, null, 8, ["onClick"]))
                            : _createCommentVNode("", true),
                          _createVNode(_component_v_divider)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_list_item, {
                    class: "version",
                    onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$router.push({name: 'versions'})))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("v." + _toDisplayString(_ctx.appVersion), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_navigation_drawer, {
          modelValue: _ctx.showFilter,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.showFilter) = $event)),
          location: "right",
          width: "90%",
          temporary: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Filter)
          ]),
          _: 1
        }, 8, ["modelValue"]),
        (_ctx.$store.state.user?.uid)
          ? (_openBlock(), _createBlock(_component_v_main, {
              key: 0,
              class: "pb-10"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_component_router_view, {
                  key: _ctx.$route.fullPath
                }))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_v_overlay, {
          "model-value": _ctx.$store.getters.hasLoading,
          class: "align-center justify-center"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_progress_circular, {
              indeterminate: "",
              size: "64",
              color: "primary"
            })
          ]),
          _: 1
        }, 8, ["model-value"]),
        _createVNode(_component_v_snackbar, {
          modelValue: _ctx.snackbar,
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.snackbar) = $event)),
          timeout: 2000
        }, {
          actions: _withCtx(() => [
            _createVNode(_component_v_btn, {
              color: "blue",
              variant: "text",
              onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.snackbar = false))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Close ")
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.snackbarText) + " ", 1)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_ConfirmDialog, { ref: "confirmDialog" }, null, 512)
  ], 64))
}