import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16fc8b2d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-4" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12 col-md-6 position-relative" }
const _hoisted_4 = {
  key: 0,
  class: "song-list"
}
const _hoisted_5 = { class: "d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_combobox = _resolveComponent("v-combobox")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_v_combobox, {
          modelValue: _ctx.band,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.band) = $event)),
          items: _ctx.$store.state.bands,
          ref: "bands",
          "item-title": "name",
          "auto-select-first": true,
          "item-value": "uid",
          variant: "solo",
          "custom-filter": _ctx.filter,
          "menu-props": {
                      maxHeight: 300
                    },
          rules: [
                      v => typeof v === 'object' ? true : 'Add band'
                    ],
          "return-object": true,
          label: "Band"
        }, null, 8, ["modelValue", "items", "custom-filter", "rules"])
      ])
    ]),
    (_ctx.band.uid)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.songs, (song) => {
            return (_openBlock(), _createBlock(_component_v_card, {
              key: song.uid,
              class: "mb-3"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_form, {
                      modelValue: _ctx.validity[song.uid],
                      "onUpdate:modelValue": ($event: any) => ((_ctx.validity[song.uid]) = $event),
                      class: "d-flex"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          modelValue: _ctx.control[song.uid],
                          "onUpdate:modelValue": ($event: any) => ((_ctx.control[song.uid]) = $event),
                          placeholder: "Placeholder",
                          variant: "plain",
                          "hide-details": true,
                          class: "'pt-0'",
                          rules: [
                v => !v || `Can't be empty`
              ]
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "rules"]),
                        (_ctx.control[song.uid] !== song.name)
                          ? (_openBlock(), _createBlock(_component_v_btn, {
                              key: 0,
                              onClick: ($event: any) => (_ctx.resetSong(song)),
                              class: "ml-auto",
                              size: "small"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(" Reset ")
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          : _createCommentVNode("", true),
                        (_ctx.control[song.uid] !== song.name)
                          ? (_openBlock(), _createBlock(_component_v_btn, {
                              key: 1,
                              onClick: ($event: any) => (_ctx.updateSongName(song)),
                              class: "ml-2",
                              color: "primary",
                              disabled: _ctx.validity[song.uid],
                              size: "small"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(" Save ")
                              ]),
                              _: 2
                            }, 1032, ["onClick", "disabled"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["modelValue", "onUpdate:modelValue"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          }), 128)),
          (_ctx.showNew)
            ? (_openBlock(), _createBlock(_component_v_card, {
                key: 0,
                class: "mb-3"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_form, {
                        modelValue: _ctx.validityNew,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.validityNew) = $event)),
                        class: "d-flex"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.newSongName,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newSongName) = $event)),
                            placeholder: "Placeholder",
                            variant: "plain",
                            autofocus: "",
                            "hide-details": true,
                            class: "'pt-0'",
                            rules: [
                v => !v || `Can't be empty`
              ]
                          }, null, 8, ["modelValue", "rules"]),
                          _createVNode(_component_v_btn, {
                            onClick: _ctx.removeNew,
                            class: "ml-auto",
                            size: "small"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Cancel ")
                            ]),
                            _: 1
                          }, 8, ["onClick"]),
                          _createVNode(_component_v_btn, {
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.createSong())),
                            class: "ml-2",
                            color: "primary",
                            disabled: _ctx.validityNew,
                            size: "small"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Save ")
                            ]),
                            _: 1
                          }, 8, ["disabled"])
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            (!_ctx.showNew)
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 0,
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showNew = true)),
                  class: "ml-2",
                  color: "primary"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Add new ")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}