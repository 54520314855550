import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42cc078d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "category-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_chip = _resolveComponent("v-chip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
      return (_openBlock(), _createBlock(_component_v_chip, {
        key: category.uid,
        onClick: ($event: any) => (_ctx.filterByCategory(category)),
        style: _normalizeStyle('--category-color:' + category.color || 'transparent'),
        class: _normalizeClass(["mr-1 mb-1 cursor-default category-chip elevation-2 cursor-pointer", {'category-chip--active': _ctx.$store.state.filter.categories.includes(category.uid)}]),
        variant: "outlined"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(category.name), 1)
        ]),
        _: 2
      }, 1032, ["onClick", "style", "class"]))
    }), 128))
  ]))
}