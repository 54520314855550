import {createStore, Store, useStore as baseUseStore,} from 'vuex'
import {UserModel} from "@/shared/user.model";
import {StoreModel} from "@/store/store.model";
import {InjectionKey} from "vue";
import {NameModel, SongModel} from "@/shared/models/song.model";
import {MySongModel} from "@/shared/models/mySong.model";
import {CategoryModel} from "@/shared/models/category.model";

export const key: InjectionKey<Store<StoreModel>> = Symbol();

// Create a new store instance.
export const store = createStore<StoreModel>({
    state () {
        return {
            filter: {
                categories: [],
                band: undefined,
                song: '',
                search: ''
            },
            message: '',
            loading: {},
            user: null,
            bands: [],
            categories: [],
            songs: [],
            filterSongs: [],
            mySongs: [],
            filteredMySongs: []
        }
    },
    mutations: {
        resetFilter(state) {
          state.filter = {
              categories: [],
              band: undefined,
              song: '',
              search: ''
          }
        },
        setFilterCategories(state, categories: string[]) {
            state.filter.categories = categories;
            store.commit('populateFilteredSongs');
        },
        setFilterBand(state, band: NameModel) {
            state.filter.band = band;
            store.commit('populateFilteredSongs');
        },
        setFilterSong(state, song: string) {
            state.filter.song = song;
            store.commit('populateFilteredSongs');
        },
        setFilterSearch(state, search: string) {
            state.filter.search = search;
            store.commit('populateFilteredSongs');
        },
        setMessage(state, message: string) {
            state.message = message;
        },
        resetMessage(state) {
            state.message = '';
        },
        loading(state, key: string) {
            state.loading[key] = true;
        },
        loaded(state, key: string) {
            state.loading[key] = false;
        },
        setUser (state, user: UserModel) {
            state.user = user;
        },
        removeUser(state) {
            state.user = null;
        },
        populateSongs(state, songs: SongModel[]) {
            state.songs = songs;
        },
        populateFilterSongs(state, songs: SongModel[]) {
            state.filterSongs = songs;
        },
        populateBands(state, bands: NameModel[]) {
            state.bands = bands;
        },
        populateCategories(state, categories: CategoryModel[]) {
            state.categories = categories;
        },
        populateMySongs(state, songs: MySongModel[]) {
            state.mySongs = colorMap(songs);
            store.commit('populateFilteredSongs');
        },
        populateFilteredSongs(state) {
            let list: MySongModel[] = [...state.mySongs];
            if (store.getters.hasFilter) {
                if (state.filter.song) {
                    list = list.filter(el => el.song === state.filter.song);
                }
                if (state.filter.band) {
                    list = list.filter(el => el.band === (state.filter.band as NameModel).name);
                }
                if (state.filter.categories.length > 0) {
                    list = list.filter(el => checker(el.categories, state.filter.categories));
                }
                if (state.filter.search) {
                    list = list.filter(el =>
                        el.band.toLowerCase().includes(state.filter.search.toLowerCase())
                        || el.song.toLowerCase().includes(state.filter.search.toLowerCase())
                        || el.lyrics.toLowerCase().includes(state.filter.search.toLowerCase())
                    )
                }
            }
            state.filteredMySongs = list;
        },
    },
    getters: {
        isAuth(state) {
            return !!state.user;
        },
        getMySongByUid: (state) => (uid: string) => {
            return state.mySongs.find(el => el.uid === uid) || undefined;
        },
        getCategories: (state) => (uids: string[]) => {
            return state.categories.filter(el => uids.find(u => el.uid === u));
        },
        bandByName: (state) => (name: string) => {
            return state.bands.find(el => el.name === name);
        },
        songByName: (state) => (name: string) => {
            return state.songs.find(el => el.name === name);
        },
        hasLoading: (state) => {
            return Object.values(state.loading).some(el => el);
        },
        hasFilter: (state) => {
            return state.filter.search || state.filter.song || state.filter.band || state.filter.categories.length > 0;
        }
    }
});

export function useStore () {
    return baseUseStore(key)
}

function colorMap(songs: MySongModel[]): MySongModel[] {
    const colors = [
        '#e81e63',
        '#00bcd4',
        '#673ab7',
        '#8bc34a',
        '#3f51b5',
        '#ff9800',
        '#2196f3',
        '#795548',
        '#03a9f4',
        '#607d8b',
        '#009688',
        '#cddc39',
        '#9c27b0',
        '#ffeb3b',
        '#ff5722',
        '#9e9e9e',
        '#4caf50',
        '#ffc107',
    ];
    // @ts-ignore
    const bands = Array.from(new Set(songs.map(el => el.band)));
    const colorsLength = colors.length;

    const colorMap = bands.reduce((acc: {[key: string]: string}, band, index) => {
        const colorIndex = index % colorsLength;
        return {
            ...acc,
            [band]: colors[colorIndex]
        };
    }, {});

    return songs.map(s => ({...s, bandColor: colorMap[s.band]}))
}

const checker = (arr: string[], target: string[]) => target.every(v => arr.includes(v));
