import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SongView = _resolveComponent("SongView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.songIndex >= 0)
      ? (_openBlock(), _createBlock(_component_SongView, {
          key: 0,
          index: _ctx.songIndex
        }, null, 8, ["index"]))
      : _createCommentVNode("", true)
  ]))
}