
import {Options, Vue} from "vue-class-component";

@Options({
  data() {
    return {
      confirmText: '',
      dialog: false
    }
  }
})
export default class ConfirmDialog extends Vue {

  private confirmText!: string;

  private dialog!: boolean;

  // eslint-disable-next-line no-unused-vars
  private resolve!: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  private reject!: (value: boolean) => void;

  public open(confirmText: string): Promise<boolean> {
    return new Promise((resolve,reject) => {
      this.resolve = resolve;
      this.reject = reject;
      this.confirmText = confirmText;
      this.dialog = true;
    })
  }

  private confirm() {
    this.resolve(true);
    this.dialog = false;
  }

  private cancel() {
    this.resolve(false);
    this.dialog = false;
  }
}
