import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-285fa67a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-4 band-list" }
const _hoisted_2 = { class: "d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bands, (band) => {
      return (_openBlock(), _createBlock(_component_v_card, {
        key: band.uid,
        class: "mb-3"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_form, {
                modelValue: _ctx.validity[band.uid],
                "onUpdate:modelValue": ($event: any) => ((_ctx.validity[band.uid]) = $event),
                class: "d-flex"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.control[band.uid],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.control[band.uid]) = $event),
                    placeholder: "Placeholder",
                    variant: "plain",
                    "hide-details": true,
                    class: "'pt-0'",
                    rules: [
                v => !v || `Can't be empty`
              ]
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "rules"]),
                  (_ctx.control[band.uid] !== band.name)
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 0,
                        onClick: ($event: any) => (_ctx.resetBand(band)),
                        class: "ml-auto",
                        size: "small"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Reset ")
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    : _createCommentVNode("", true),
                  (_ctx.control[band.uid] !== band.name)
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 1,
                        onClick: ($event: any) => (_ctx.updateBandName(band)),
                        class: "ml-2",
                        color: "primary",
                        disabled: _ctx.validity[band.uid],
                        size: "small"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Save ")
                        ]),
                        _: 2
                      }, 1032, ["onClick", "disabled"]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1024))
    }), 128)),
    (_ctx.showNew)
      ? (_openBlock(), _createBlock(_component_v_card, {
          key: 0,
          class: "mb-3"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_form, {
                  modelValue: _ctx.validityNew,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.validityNew) = $event)),
                  class: "d-flex"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      modelValue: _ctx.newBandName,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newBandName) = $event)),
                      placeholder: "Placeholder",
                      variant: "plain",
                      autofocus: "",
                      "hide-details": true,
                      class: "'pt-0'",
                      rules: [
                v => !v || `Can't be empty`
              ]
                    }, null, 8, ["modelValue", "rules"]),
                    _createVNode(_component_v_btn, {
                      onClick: _ctx.removeNew,
                      class: "ml-auto",
                      size: "small"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Cancel ")
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_v_btn, {
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.createBand())),
                      class: "ml-2",
                      color: "primary",
                      disabled: _ctx.validityNew,
                      size: "small"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Save ")
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.showNew)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 0,
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showNew = true)),
            class: "ml-2",
            color: "primary"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Add new ")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}