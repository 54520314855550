
import {Options, Vue} from "vue-class-component";
import {isAdmin} from "@/shared/const";
import {UserModel} from "@/shared/user.model";
import {BandModel} from "@/shared/models/song.model";
import FirebaseService from "@/shared/services/firebase.service";

@Options({
  data() {
    return {
      control: {},
      validity: {},
      validityNew: undefined,
      newBandName: 'New band',
      showNew: false,
      bands: []
    }
  },
  watch: {
    '$store.state.bands': {
      handler(bands) {
        this.bands = [...bands];
        if (bands.length > 0) {
          bands.forEach((el: BandModel) => {
            this.setControl(el);
          });
        }
      },
      deep: true,
      immediate: true
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (isAdmin((vm.$store.state.user as UserModel).uid || '')) {
        return true;
      } else {
        vm.$router.push('home');
      }
    })
  }

})
export default class BandsList extends Vue {

  firebaseService = new FirebaseService();

  bands!: BandModel[];

  control!: { [key: string]: string };

  validity!: { [key: string]: undefined | string };

  validityNew!: undefined | string;

  newBandName!: string;

  showNew!: boolean;

  mounted() {
    document.title = `Bands | ${process.env.VUE_APP_TITLE}`;
  }

  beforeUnmount() {
    this.$store.commit('populateBands', this.bands);
  }

  updateBandName(band: BandModel) {
    const uid = band.uid as string;
    const name = this.control[uid];
    const newBand = {...band, name};
    this.firebaseService.updateBand(newBand, uid, band.name)
        .then(() => {
          const index = this.bands.findIndex(el => el.uid === uid);
          this.bands[index] = {...newBand};
        })
        .catch(err => {
          console.error(err);
        });
  }

  removeNew() {
    this.showNew = false;
    this.validityNew = undefined;
    this.newBandName = 'New band';
  }

  setControl(band: BandModel) {
    const uid = band.uid as string;
    this.control[uid] = band.name;
    this.validity[uid] = undefined;
  }

  resetBand(band: BandModel) {
    this.setControl(band);
  }

  createBand() {
    const band: BandModel = {
      name: this.newBandName,
    };
    this.firebaseService.createBand(band)
        .then((res: any) => {
          this.removeNew();
          const newBand = {...band, uid: res.id};
          this.setControl(newBand);
          this.bands.push({...band, uid: res.id});
        })
        .catch((err) => {
          console.error(err);
        });
  }
}
