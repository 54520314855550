// Firebase
import { initializeApp } from 'firebase/app';
import { getFirestore, addDoc,  collection, getDocs, query, where, orderBy, updateDoc, doc} from "firebase/firestore";
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import {UserModel} from "@/shared/user.model";
import {key} from "@/store/store.module";
import {useStore} from "vuex";
import {BandModel, NameModel, SongModel} from "@/shared/models/song.model";
import {CategoryModel} from "@/shared/models/category.model";
import {MySongModel} from "@/shared/models/mySong.model";
import MD5 from "crypto-js/md5";


const firebaseConfig = {

    apiKey: "AIzaSyBOdJTMkjiAIxYOLHlBKKlOvYy116IXaCs",

    authDomain: "myrandomsongs-12616.firebaseapp.com",

    databaseURL: "https://myrandomsongs-12616.firebaseio.com",

    projectId: "myrandomsongs-12616",

    storageBucket: "myrandomsongs-12616.appspot.com",

    messagingSenderId: "503272450755",

    appId: "1:503272450755:web:58524fded29154d7c11076"

};
// Initialize Firebase

export default class FirebaseService {
    private static instance: FirebaseService;

    private firebase = initializeApp(firebaseConfig);

    private provider = new GoogleAuthProvider();

    private auth = getAuth();

    private user: UserModel | undefined;

    private db = getFirestore(this.firebase);

    private store = useStore(key);

    get localStorageSongs(): string {
        return localStorage.getItem('songs') || '';
    }

    get localStorageBands(): string {
        return localStorage.getItem('bands') || '';
    }

    get localStorageCategories(): string {
        return localStorage.getItem('categories') || '';
    }

    login() {
        signInWithPopup(this.auth, this.provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential?.accessToken;
                // The signed-in user info.
                const user = result.user;
                // ...
                // @ts-ignore
                this.user = user as UserModel;
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
                console.error(error);
            });
    }

    logout() {
        this.auth.signOut();
    }

    checkLogin() {

        if (!navigator.onLine) {
            this.loadOfflineUser();
            return;
        }

        onAuthStateChanged(this.auth, (user) => {
            if (user) {
                this.store.commit('setUser', user);
                this.loadAppData();
            } else {
                this.store.commit('removeUser', user);
            }
        });
    }

    getSongs(bandId: string, commit: 'populateSongs' | 'populateFilterSongs' = 'populateSongs') {
        const q = query(
            collection(this.db, "songs"),
            where("band", "==", bandId),
            orderBy('name', 'asc')
        );

        getDocs(q).then(res => {
            this.store.commit(commit, res.docs.map((doc => ({
                uid: doc.id,
                ...doc.data()
            }))));
        });
    }

    getCategories() {
        if (!navigator.onLine) {
            if (this.localStorageCategories) {
                this.loadCategoriesFromStore();
            }
            return;
        }
        return new Promise(resolve => {
            const q = query(
                collection(this.db, "categories"),
                where("userId", "==", this.store.state.user?.uid),
                orderBy('name', 'asc')
            );

            getDocs(q).then(res => {
                this.store.commit('populateCategories', res.docs.map((doc => ({
                    uid: doc.id,
                    ...doc.data()
                }))));
                localStorage.setItem('categories', JSON.stringify(this.store.state.categories));
                resolve(true);
            });
        });
    }

    getBands() {
        if (!navigator.onLine) {
            if (this.localStorageBands) {
                this.loadBandsFromStore();
            }
            return;
        }

        this.loading('getBands');
        const q = query(
            collection(this.db, "bands"),
            orderBy('name', 'asc')
        );

        getDocs(q).then(res => {
            this.store.commit('populateBands', res.docs.map((doc => ({
                uid: doc.id,
                ...doc.data()
            }))));
            localStorage.setItem('bands', JSON.stringify(this.store.state.bands));
        })
            .finally(() => {
                this.loaded('getBands');
            });
    }

    async getMySongs() {
        if (!navigator.onLine) {
            if (this.localStorageSongs) {
                this.loadMySongsFromStore();
            }
            return;
        }

        this.loading('getMySongs');
        const q = this.generateMySongsQuery();



        if (this.localStorageSongs) {
            const sameVersion = await this.sameMySongVersion();
            if (this.localStorageSongs && sameVersion) {
                this.loadMySongsFromStore();
                return;
            }
        }

        getDocs(q).then(res => {
            this.store.commit('populateMySongs', res.docs.map((doc => ({
                uid: doc.id,
                ...doc.data()
            }))));

            localStorage.setItem('songs', JSON.stringify(this.store.state.mySongs));
            this.updateMySongsVersion();
        })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                this.loaded('getMySongs');
            });
    }

    updateMySongsVersion(resetVersion = false) {
        return new Promise(resolve => {

            this.loading('updateMySongsVersion');
            const q = query(
                collection(this.db, "mySongsVersion"),
                where("userId", "==", this.store.state.user?.uid)
            );

            getDocs(q).then(res => {

                const data = {
                    version: resetVersion ? '' : this.mdFive(this.localStorageSongs || '')
                };

                const document = res.docs.map(d => d.data())[0];

                if (!document) {
                    const mySongVersion = {
                        userId: this.store.state.user?.uid,
                        ...data
                    };
                    addDoc(collection(this.db, 'mySongsVersion'), mySongVersion)
                        .then(res => {
                            resolve(true);
                        })
                        .catch(err => {
                            resolve(false);
                            console.error(err);
                        })
                        .finally(() => {
                            this.loaded('updateMySongsVersion');
                        });
                    return;
                }

                res.docs.map((document) => {

                    const docRef = doc(this.db, "mySongsVersion", document.id);
                    updateDoc(docRef, data)
                        .then(() => {
                            resolve(true);
                        })
                        .catch(error => {
                            resolve(false);
                            console.error(error);
                        })
                });

            })
                .catch(err => {
                    resolve(false);
                    console.error(err);
                })
                .finally(() => {
                    this.loaded('updateMySongsVersion');
                });
        });
    }

    sameMySongVersion(): Promise<boolean> {
        return new Promise(resolve => {
            const q = query(
                collection(this.db, "mySongsVersion"),
                where("userId", "==", this.store.state.user?.uid)
            );

            getDocs(q).then(res => {
                const document = (res.docs.map(d => d.data()))[0];
                if (!document) {
                    resolve(false);
                    return;
                }
                resolve(document.version === this.mdFive(this.localStorageSongs));
            })
                .catch(err => {
                    console.error(err);
                    resolve(false);
                });

        });
    }

    mdFive(s: string): string {
        return MD5(s).toString();
    }

    loadMySongsFromStore() {
        this.store.commit('populateMySongs', JSON.parse(this.localStorageSongs));
    }

    loadBandsFromStore() {
        this.store.commit('populateBands', JSON.parse(this.localStorageBands));
    }

    loadCategoriesFromStore() {
        this.store.commit('populateCategories', JSON.parse(this.localStorageCategories));
    }

    updateMySongsBand(oldName: string, newName: string) {
        this.updateMySongField('band', oldName, newName);
    }

    updateMySongsSong(oldName: string, newName: string) {
        this.updateMySongField('song', oldName, newName);
    }

    updateMySongField(field: string, oldName: string, newName: string) {
        this.loading('updateMySongField');
        const q = query(
            collection(this.db, "mySongs"),
            where(field, "==", oldName)
        );

        getDocs(q).then(res => {

            const data = {
                [field]: newName
            };

            res.docs.map((document) => {

                const docRef = doc(this.db, "mySongs", document.id);
                updateDoc(docRef, data)
                    .then(() => {})
                    .catch(error => {
                        console.error(error);
                    })
            });

        })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                this.loaded('updateMySongField');
            });
    }

    createSong(song: SongModel) {
        this.loading('createSong');
        return new Promise((resolve, reject) => {
            addDoc(collection(this.db, 'songs'), song)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                    console.error(err);
                })
                .finally(() => {
                    this.loaded('createSong');
                });
        });
    }

    updateSong(song: SongModel, uid: string, songOldName: string,) {
        this.loading('updateSong');
        return new Promise((resolve, reject) => {
            const document = doc(this.db, "songs", uid);

            updateDoc(document, song as any)
                .then(res => {
                    this.updateMySongsSong(songOldName, song.name);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                    console.error(err);
                })
                .finally(() => {
                    this.loaded('updateSong');
                });
        });
    }

    createBand(band: NameModel) {
        this.loading('createBand');
        return new Promise((resolve, reject) => {
            addDoc(collection(this.db, 'bands'), band)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                    console.error(err);
                })
                .finally(() => {
                    this.loaded('createBand');
                });
        });
    }

    updateBand(band: BandModel, uid: string, bandOldName: string, ) {
        this.loading('updateBand');
        return new Promise((resolve, reject) => {
            const document = doc(this.db, "bands", uid);

            updateDoc(document, band as any)
                .then(res => {
                    this.updateMySongsBand(bandOldName, band.name);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                    console.error(err);
                })
                .finally(() => {
                    this.loaded('updateBand');
                });
        });
    }

    createCategory(category: CategoryModel) {
        this.loading('createCategory');
        return new Promise((resolve, reject) => {
            addDoc(collection(this.db, 'categories'), category)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                    console.error(err);
                })
                .finally(() => {
                    this.loaded('createCategory');
                });
        });
    }

    updateCategory(category: CategoryModel, uid: string) {
        this.loading('updateCategory');
        return new Promise((resolve, reject) => {
            const document = doc(this.db, "categories", uid);

            updateDoc(document, category as any)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                    console.error(err);
                })
                .finally(() => {
                    this.loaded('updateCategory');
                });
        });
    }

    createMySong(song: MySongModel) {
        this.loading('createMySong');
        return new Promise((resolve, reject) => {
            const q = query(
                collection(this.db, "mySongs"),
                where("userId", "==", this.store.state.user?.uid),
                where('band', "==", song.band),
                where('song', "==", song.song),
            );

            getDocs(q).then(res => {
                if (res.docs.length > 0) {
                    this.store.commit('setMessage', 'This song is already added!');
                    reject();
                    this.loaded('createMySong');
                    return;
                }

                addDoc(collection(this.db, 'mySongs'), song)
                    .then(async (res) => {
                        await this.updateMySongsVersion(true);
                        resolve(res);
                    })
                    .catch(err => {
                        reject(err);
                        console.error(err);
                    })
                    .finally(() => {
                        this.loaded('createMySong');
                    });

            });
        });
    }

    updateMySong(song: MySongModel, uid: string) {
        this.loading('updateMySong');
        return new Promise((resolve, reject) => {

            const q = query(
                collection(this.db, "mySongs"),
                where("userId", "==", this.store.state.user?.uid),
                where('band', "==", song.band),
                where('song', "==", song.song),
            );

            getDocs(q).then(res => {
                if (res.docs.length === 1 && res.docs[0].id !== uid) {
                    this.store.commit('setMessage', 'This song is already added!');
                    reject();
                    this.loaded('updateMySong');
                    return;
                }

                const document = doc(this.db, "mySongs", uid);

                updateDoc(document, song as any)
                    .then(async (res) => {
                        await this.updateMySongsVersion(true);
                        resolve(res);
                    })
                    .catch(err => {
                        reject(err);
                        console.error(err);
                    })
                    .finally(() => {
                        this.loaded('updateMySong');
                    });

            });
        });
    }

    loading(loadKey: string) {
        this.store.commit('loading', {key: loadKey});
    }

    loaded(loadKey: string) {
        this.store.commit('loaded', {key: loadKey});
    }

    private generateMySongsQuery() {
        const q = query(
            collection(this.db, "mySongs"),
            where("userId", "==", this.store.state.user?.uid),
            orderBy('band', 'asc'),
            orderBy('song', 'asc')
        );
        return query(q);
    }

    private loadOfflineUser() {
        const DBOpenRequest = indexedDB.open("firebaseLocalStorageDb");
        DBOpenRequest.onsuccess = (e) => {
            const db = DBOpenRequest.result;
            const transaction = db.transaction("firebaseLocalStorage", "readonly");
            const objectStore = transaction.objectStore("firebaseLocalStorage");
            const request = objectStore.openCursor();
            request.onsuccess = (event) => {
                if (!event?.target) {
                    return;
                }
                const cursor = (event.target as any).result;
                if (cursor) {
                    this.store.commit('setUser', {...cursor.value.value});
                    this.loadAppData();
                    cursor.continue();
                } else {
                    // no more results
                }
            };
        };
    }

    private loadAppData() {
        this.getBands();
        this.getCategories();
        this.getMySongs();
    }
}
