
import {Options, Vue} from "vue-class-component";
import SongView from "@/components/SongView.vue";

@Options({
  components: {SongView},
  watch: {
    '$store.state.mySongs': {
      handler(newValue) {
        if (newValue.length > 0) {
          this.setData();
        }
      }
    }
  }
})
export default class ViewSong extends Vue {

  songIndex = -1;

  mounted() {
    if (this.$store.state.mySongs.length === 0) {
      return;
    }

    this.setData();
  }

  setData() {

    this.songIndex = this.$store.state.mySongs.findIndex(el => el.uid === this.$route.params.songId as string);

    document.title = `${this.$store.state.mySongs[this.songIndex].song} | ${process.env.VUE_APP_TITLE}`;
  }
}
