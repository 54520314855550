
import {Options, Vue} from "vue-class-component";
import {CategoryModel} from "@/shared/models/category.model";

@Options({
  props: {
    categoriesUids: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      categories: []
    }
  },
  watch: {
    categoriesUids: {
      handler() {
        this.setCategories();
      }
    }
  }
})
export default class SongCategories extends Vue {

  categoriesUids!: string[];

  categories!: CategoryModel[];

  mounted() {
    this.setCategories();
  }

  setCategories() {
    this.categories = this.$store.state.categories.filter(el => this.categoriesUids.some(uid => uid === el.uid));
  }

  filterByCategory(category: CategoryModel) {
    const currentCategoriesFilter = this.$store.state.filter.categories;
    if (currentCategoriesFilter.includes(category.uid as string)) {
      this.$store.commit('setFilterCategories', [...currentCategoriesFilter.filter(el => el !== category.uid)]);
      return;
    }

    this.$store.commit('setFilterCategories', [...currentCategoriesFilter, category.uid]);
  }
}
